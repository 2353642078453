<template>
  <section class="space-y-10">
    <asom-alert
      v-if="summary.noOfUndeclaredCoinFlushForms > 0"
      variant="warning"
      title="Undeclared Coin Flush"
      dismissible
    >
      <p class="text-sm">
        {{
          $tc(
            "screens.cashManagement.AFC.Main.hasUndeclaredTransaction",
            summary.noOfUndeclaredCoinFlushForms
          )
        }}
      </p>
    </asom-alert>
    <article>
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0 rounded-md grid sm:grid-cols-2 md:grid-cols-4">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="Cointube e-log"
          @click="$router.push('afc/cointube')"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="Coin Flushing"
          @click="$router.push('afc/coinflush')"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="Cashbox e-Log"
          @click="$router.push('afc/cashbox')"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="Cashbag e-Log"
          @click="$router.push('afc/cashbag')"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="SCCF"
          @click="$router.push('afc/sccf')"
        >
        </asom-button>
        <asom-button
          variant="primary"
          outline
          rounded="none"
          icon="swap"
          text="Station Transfer"
          @click="$router.push('afc/station-transfer')"
        >
        </asom-button>
        <asom-button
          v-if="canCreateAFCDiscrepancyReport"
          variant="primary"
          outline
          rounded="none"
          icon="clipboard"
          text="Discrepancy Report"
          @click="$router.push({ name: 'Create Discrepancy Report AFC' })"
        >
        </asom-button>
      </section>
    </article>

    <asom-alert v-if="error" variant="error" :error-message="error" />

    <article>
      <h3 class="subheader">
        {{ $t("screens.cashManagement.AFC.Main.stationOverviewTitle") }}
      </h3>
      <br />
      <dl class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              In Station
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.stationAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ formatCurrency(summary.stationLimitAllowance) }}</strong> Limit
              allowable
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt class="text-base font-normal text-gray-900">
              In AFC
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.afcAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ formatCurrency(summary.afcLimitAllowance) }}</strong> Limit
              allowable
            </li>
          </ul>
        </aside>
      </dl>
    </article>
    <article>
      <h3 class="subheader">
        Current AFC Status
      </h3>
      <br />
      <dl class="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex space-x-1"
            >
              <asom-icon :icon="'file-document'" size="sm" />
              <span>Certis Bags</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.certisBagsAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ summary.certisBagsAtAfc }}</strong> in <strong>AFC</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ summary.certisBagsAtPsc }}</strong> in <strong>PSC</strong>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex space-x-1"
            >
              <asom-icon :icon="'inbox'" size="sm" />
              <span>TE Bags</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.teBagsAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.teBagFromPSMCount }}</strong> from <strong>PSM</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.teBagFromTicketCount }}</strong> from
                <strong>Pre-encoded Tickets</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.teBagFromCashFloatCount }}</strong> from
                <strong>Manual Change</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.teBagFromCoinFlushCount }}</strong> from
                <strong>Coin Flush</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.teBagFromOthersCount }}</strong> from
                <strong>Others</strong></span
              >
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex space-x-1"
            >
              <asom-icon :icon="'toolbox'" size="sm" />
              <span>Coin Boxes</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ summary.noOfCoinBoxes }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.coinboxesAtGtm }}</strong> in <strong>GTM/TUK</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.noOfFullCoinBoxesAtAfc }}</strong> in <strong>AFC</strong></span
              >
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                Pending Collection
              </span>
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.noOfEmptyCoinBoxesAtAfc }}</strong> in
                <strong>AFC</strong></span
              >
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                Empty
              </span>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex space-x-1"
            >
              <asom-icon :icon="'briefcase'" size="sm" />
              <span>Note Boxes</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ summary.noOfNoteBoxes }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.noteboxesAtGtm }}</strong> in <strong>GTM/TUK</strong></span
              >
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.noOfFullNoteBoxesAtAfc }}</strong> in <strong>AFC</strong></span
              >
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
              >
                Pending Collection
              </span>
            </li>
            <li class="px-3 py-2 space-x-1">
              <span
                ><strong>{{ summary.noOfEmptyNoteBoxesAtAfc }}</strong> in
                <strong>AFC</strong></span
              >
              <span
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
              >
                Empty
              </span>
            </li>
          </ul>
        </aside>
        <aside class="rounded-lg bg-white overflow-hidden shadow">
          <div class="space-y-1 border-b boder-gray-200 p-3">
            <dt
              class="text-base font-normal text-gray-900 items-center flex space-x-1"
            >
              <asom-icon :icon="'shape-circle'" size="sm" />
              <span>Coin Tubes</span>
            </dt>
            <dd class="text-3xl font-semibold text-gray-900 text-left">
              {{ formatCurrency(summary.coinTubesAmount) }}
            </dd>
          </div>
          <ul class="divide-y divide-gray-200">
            <li class="px-3 py-2">
              <strong>{{ summary.noOf10CentCoinTubes }}</strong> 10¢ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ summary.noOf20CentCoinTubes }}</strong> 20¢ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ summary.noOf50CentCoinTubes }}</strong> 50¢ <strong>coin tubes</strong>
            </li>
            <li class="px-3 py-2">
              <strong>{{ summary.noOf1DollarCoinTubes }}</strong> $1 <strong>coin tubes</strong>
            </li>
          </ul>
        </aside>
      </dl>
    </article>

    <!-- <article>
    <h3 class="subheader">
      Activities
    </h3>
    <br>
    <activity-log-list :data="summary.activityLogs" />
  </article> -->
  </section>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { getAFCSummary } from "@/services/cashManagement.service";
import { formatCurrency } from "../../../helpers/numbers";
// import ActivityLogList from '../_ActivityLogList.vue';
export default {
  // components: {
  //   ActivityLogList
  // },
  data() {
    return {
      isLoading: false,
      error: null,
      summary: {
        stationAmount: 0,
        stationLimitAllowance: 0,
        afcAmount: 0,
        afcLimitAllowance: 0,
        certisBagsAmount: 0,
        certisBagsAtAfc: 0,
        certisBagsAtPsc: 0,
        teBagsAmount: 0,
        teBagFromPSMCount: 0,
        teBagFromTicketCount: 0,
        teBagFromCashFloatCount: 0,
        teBagFromCoinFlushCount: 0,
        teBagFromOthersCount: 0,
        noOfCoinBoxes: 0,
        coinboxesAtGtm: 0,
        noOfFullCoinBoxesAtAfc: 0,
        noOfEmptyCoinBoxesAtAfc: 0,
        noOfNoteBoxes: 0,
        noteboxesAtGtm: 0,
        noOfFullNoteBoxesAtAfc: 0,
        noOfEmptyNoteBoxesAtAfc: 0,
        coinTubesAmount: 0,
        noOf10CentCoinTubes: 0,
        noOf20CentCoinTubes: 0,
        noOf50CentCoinTubes: 0,
        noOf1DollarCoinTubes: 0,
        noOfUndeclaredCoinFlushForms: 0,
        activityLogs: [],
      },
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      canCreateAFCDiscrepancyReport: 'auth/canCreateAFCDiscrepancyReport',
    }),
  },
  methods: {
    formatCurrency,
    async loadData() {
      this.error = null;
      this.isLoading = true;
      await this.$store.dispatch('cashManagement/loadStationCashAccounts');
      const result = await getAFCSummary(this.afcId);
      if (result.success) {
        this.setData(result);
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
      this.isLoading = false;
    },
    setData(apiResult) {
      this.summary.stationAmount = get(
        apiResult,
        "payload.data.stationAmount",
        0
      );
      this.summary.stationLimitAllowance = get(
        apiResult,
        "payload.data.stationLimitAllowance",
        0
      );
      this.summary.afcAmount = get(apiResult, "payload.data.afcAmount", 0);
      this.summary.afcLimitAllowance = get(
        apiResult,
        "payload.data.afcLimitAllowance",
        0
      );
      this.summary.certisBagsAmount = get(
        apiResult,
        "payload.data.certisBagsAmount",
        0
      );
      this.summary.certisBagsAtAfc = get(
        apiResult,
        "payload.data.certisBagsAtAfc",
        0
      );
      this.summary.certisBagsAtPsc = get(
        apiResult,
        "payload.data.certisBagsAtPsc",
        0
      );
      this.summary.teBagsAmount = get(
        apiResult,
        "payload.data.teBagsAmount",
        0
      );
      this.summary.teBagFromPSMCount = get(
        apiResult,
        "payload.data.teBagFromPSMCount",
        0
      );
      this.summary.teBagFromTicketCount = get(
        apiResult,
        "payload.data.teBagFromTicketCount",
        0
      );
      this.summary.teBagFromCashFloatCount = get(
        apiResult,
        "payload.data.teBagFromCashFloatCount",
        0
      );
      this.summary.teBagFromCoinFlushCount = get(
        apiResult,
        "payload.data.teBagFromCoinFlushCount",
        0
      );
      this.summary.teBagFromOthersCount = get(
        apiResult,
        "payload.data.teBagFromOthersCount",
        0
      );
      this.summary.noOfCoinBoxes = get(
        apiResult,
        "payload.data.noOfCoinBoxes",
        0
      );
      this.summary.coinboxesAtGtm = get(
        apiResult,
        "payload.data.coinboxesAtGtm",
        0
      );
      this.summary.noOfFullCoinBoxesAtAfc = get(
        apiResult,
        "payload.data.noOfFullCoinBoxesAtAfc",
        0
      );
      this.summary.noOfEmptyCoinBoxesAtAfc = get(
        apiResult,
        "payload.data.noOfEmptyCoinBoxesAtAfc",
        0
      );
      this.summary.noOfNoteBoxes = get(
        apiResult,
        "payload.data.noOfNoteBoxes",
        0
      );
      this.summary.noteboxesAtGtm = get(
        apiResult,
        "payload.data.noteboxesAtGtm",
        0
      );
      this.summary.noOfFullNoteBoxesAtAfc = get(
        apiResult,
        "payload.data.noOfFullNoteBoxesAtAfc",
        0
      );
      this.summary.noOfEmptyNoteBoxesAtAfc = get(
        apiResult,
        "payload.data.noOfEmptyNoteBoxesAtAfc",
        0
      );
      this.summary.coinTubesAmount = get(
        apiResult,
        "payload.data.coinTubesAmount",
        0
      );
      this.summary.noOf10CentCoinTubes = get(
        apiResult,
        "payload.data.noOf10CentCoinTubes",
        0
      );
      this.summary.noOf20CentCoinTubes = get(
        apiResult,
        "payload.data.noOf20CentCoinTubes",
        0
      );
      this.summary.noOf50CentCoinTubes = get(
        apiResult,
        "payload.data.noOf50CentCoinTubes",
        0
      );
      this.summary.noOf1DollarCoinTubes = get(
        apiResult,
        "payload.data.noOf1DollarCoinTubes",
        0
      );
      this.summary.noOfUndeclaredCoinFlushForms = get(
        apiResult,
        "payload.data.noOfUndeclaredCoinFlushForms",
        0
      );
      // this.summary.activityLogs = get(apiResult, 'payload.data.activityLogs', []);
    },
  },
};
</script>
